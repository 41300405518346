@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: 'Avenir', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url("../public/fonts/Avenir/Avenir-Roman.eot");
  src: url("../public/fonts/Avenir/Avenir-Roman.eot#iefix") format('embedded-opentype'),
  url("../public/fonts/Avenir/Avenir-Roman.woff") format('woff'),
  url("../public/fonts/Avenir/Avenir-Roman.woff2") format('woff2'),
  url("../public/fonts/Avenir/Avenir-Roman.ttf") format('truetype'),
  url("../public/fonts/Avenir/Avenir-Roman.svg#Avenir-Roman") format('svg');
}

@font-face {
  font-family: 'CaslonPro';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/CaslonPro/ACaslonPro-Regular.eot');
  src: url('../public/fonts/CaslonPro/ACaslonPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/CaslonPro/ACaslonPro-Regular.woff') format('woff'),
  url('../public/fonts/CaslonPro/ACaslonPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Futura/FuturaLT.eot');
  src: url('../public/fonts/Futura/FuturaLT.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/Futura/FuturaLT.woff') format('woff'),
  url('../public/fonts/Futura/FuturaLT.woff2') format('woff2'),
  url('../public/fonts/Futura/FuturaLT.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Bold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Light-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Medium-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Euclid';
  font-weight: 600;
  font-style: Italic;
  font-stretch: normal;
  src: url('../public/fonts/Euclid/Euclid-Circular-A-SemiBold-Italic.ttf') format('truetype');
}
